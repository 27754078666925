(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name neo.locale:Locales
   *
   * @description
   *
   */
  angular
    .module('neo')
    .config(config);

  function config($translateProvider, $mdDateLocaleProvider, uiGmapGoogleMapApiProvider) {
    var language = 'ca_ES';
    // Change moment's locale so the 'L'-format is adjusted.
    // For example the 'L'-format is DD.MM.YYYY for German
    moment.locale('ca');

    // Set month and week names for the general $mdDateLocale service
    var localeData = moment.localeData();

    $translateProvider.useStaticFilesLoader({
      prefix: 'locales/locale-',
      suffix: '.json'
    });

    $translateProvider.preferredLanguage(language);
    $translateProvider.useSanitizeValueStrategy(null);

    $mdDateLocaleProvider.months = localeData.months();
    $mdDateLocaleProvider.shortMonths = localeData.monthsShort();
    $mdDateLocaleProvider.days = localeData.weekdays();
    $mdDateLocaleProvider.shortDays = localeData.weekdaysMin();
    // Optionaly let the week start on the day as defined by moment's locale data
    $mdDateLocaleProvider.firstDayOfWeek = localeData.firstDayOfWeek();

    // Format and parse dates based on moment's 'L'-format
    // 'L'-format may later be changed
    $mdDateLocaleProvider.parseDate = function (dateString) {
      var m = moment(dateString, 'L', true);
      return m.isValid() ? m.toDate() : new Date(NaN);
    };

    $mdDateLocaleProvider.formatDate = function (date) {
      var m = moment(date);
      return m.isValid() ? m.format('L') : '';
    };

    uiGmapGoogleMapApiProvider.configure({
      key: 'AIzaSyBgzZNxB7Kz6NrLVFLYVsOMRoGBgxS7h10',
      // v: '3.26', // defaults to latest 3.X anyhow
      libraries: 'weather,geometry,visualization',
      language: 'en'
    });
  }
}());
